import { useState } from "react";

import axios from "axios";

const useSendEmail = () => {
  const [sent, setSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const sendMail = async (text, subject) => {
    setLoading(true);
    try {
      await axios.post(
        `https://us-central1-rocsatoconservatory.cloudfunctions.net/sendMail?html=${text}&subject=${subject}`,
        text
      );
      setLoading(false);
      setSent(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return { sendMail, sent, loading };
};

export default useSendEmail;
