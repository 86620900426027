import firebase from "firebase";

var firebaseConfig = {
  apiKey: "AIzaSyBoxE-cA4p8GjuATEN9CjkEvcxFGQh9gCY",
  authDomain: "rocsatoconservatory.firebaseapp.com",
  projectId: "rocsatoconservatory",
  storageBucket: "rocsatoconservatory.appspot.com",
  messagingSenderId: "239279852741",
  appId: "1:239279852741:web:c2818f4fdb339eb42974ac",
  measurementId: "G-BWELRSS534",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
